import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  imports: [MatFormFieldModule, MatInputModule, MatIconModule, MatButtonModule],
  standalone: true,
})
export class MapComponent implements OnInit {
  private isBrowser = false;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    this.isBrowser = isPlatformBrowser(this.platformId)!;
  }

  async ngOnInit() {
    if (this.isBrowser) {
      await this.initMap();
    }
  }

  async initMap() {
    // The location of Uluru

    const position = { lat: 28.18689861953432, lng: -82.40478213209542 };

    // Request needed libraries.
    //@ts-ignore
    const { Map } = (await google.maps.importLibrary(
      'maps',
    )) as google.maps.MapsLibrary;
    const { AdvancedMarkerElement } = (await google.maps.importLibrary(
      'marker',
    )) as google.maps.MarkerLibrary;

    // The map, centered at Uluru
    const map = new Map(
      document.querySelector('#map-locations') as HTMLElement,
      {
        zoom: 10,
        center: position,
        mapId: 'DEMO_MAP_ID',
      },
    );

    // The marker, positioned at Uluru
    const marker = new AdvancedMarkerElement({
      map: map,
      position: position,
      title: 'Uluru',
    });
  }
}
