<footer>
  <div class="mobile">
    <ul>
      <li><a href="/privacy">Privacy Policy</a></li>
      <li><a href="/tos">Terms of Service</a></li>
    </ul>
  </div>

  <div class="desktop">
    <div class="col">
      <ul>
        <li><a href="#">Save 3¢/gal join now</a></li>
        <li><a href="#locations">Locations</a></li>
        <li><a href="#contact-us">Contact us</a></li>
      </ul>
    </div>
    <div class="col">
      <ul>
        <li><a href="/privacy">Privacy Policy</a></li>
        <li><a href="/tos">Terms of Service</a></li>
      </ul>
    </div>
    <div class="col logo-holder">
      <div class="logo"></div>
    </div>
  </div>
</footer>
