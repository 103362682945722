<main role="main">
  <section class="hero">
    <div class="half-hero desktop">
      <section class="illustration-text">
        <h1 class="mat-headline-1">
          The Fastest<br />
          Way to Pay
        </h1>

        <h1 class="mat-headline-3">Park. Fuel. Go!</h1>
        <p class="join mat-headline-5">
          Join Now and save 3¢/gal on top of your current credit card savings!
        </p>

        <button mat-flat-button color="primary">JOIN NOW</button>
      </section>
    </div>

    <div class="half-hero desktop">
      <section class="car-colder">
        <div class="car"></div>
      </section>
    </div>
  </section>

  <section class="hero">
    <div class="mobile">
      <section class="illustration">
        <h1 class="title">The Fastest Way to Pay</h1>
        <div class="car"></div>
        <h1 class="title">Park. Fuel. Go!</h1>
        <p class="join">
          Join Now and save 3¢/gal on top of your current credit card savings!
        </p>
      </section>
    </div>
  </section>

  <section class="hero" id="register">
    <div class="registration-holder registration-single">
      <section class="registration">
        <app-user-registration></app-user-registration>
      </section>
    </div>
  </section>

  <div id="locations"></div>
  <app-map></app-map>
  <div id="contact-us">
    <app-contact></app-contact>
  </div>

  <div class="logo-large mobile"></div>
</main>
