<div class="text-page">
  <h1>Privacy Policy</h1>
  <p>
    This Privacy Policy describes how BillMyPlate.com collects, uses, and shares
    your personal information when you visit or use our website.
  </p>
  <h2>What personal information do we collect?</h2>
  <p>We collect the following types of personal information from you:</p>
  <ul>
    <li>
      Contact information: This includes your name, email address, and phone
      number.
    </li>
    <li>
      Payment information: This includes your credit card number, expiration
      date, and billing address.
    </li>
    <li>
      Billing information: This includes your name, billing address, and
      shipping address.
    </li>
    <li>
      Device information: This includes your IP address, browser type, and
      operating system.
    </li>
    <li>
      Usage information: This includes the pages you visit on our website, the
      links you click on, and the search terms you use.
    </li>
  </ul>
  <h2>How do we use your personal information?</h2>
  <p>We use your personal information for the following purposes:</p>
  <ul>
    <li>
      To provide you with our services: We use your contact information to send
      you information about our products and services. We use your payment
      information to process your orders. We use your billing information to
      ship your orders. We use your device information to improve our website
      and to deliver relevant content to you. We use your usage information to
      analyze how you use our website so that we can improve your experience.
    </li>
    <li>
      To communicate with you: We use your contact information to send you
      important announcements, such as changes to our terms of service or
      privacy policy. We use your email address to send you marketing emails,
      such as new product announcements or special offers. You can unsubscribe
      from our marketing emails at any time.
    </li>
    <li>
      To protect our rights and property: We use your personal information to
      protect our rights and property, such as to investigate potential fraud or
      to enforce our terms of service.
    </li>
  </ul>
  <h2>Who do we share your personal information with?</h2>
  <p>
    We may share your personal information with the following third parties:
  </p>
  <ul>
    <li>
      Our service providers: We may share your personal information with our
      service providers, such as our payment processors, shipping companies, and
      marketing partners. These third parties use your personal information only
      to provide the services they are contracted to provide to us.
    </li>
    <li>
      Legal authorities: We may share your personal information with legal
      authorities if we are required to do so by law or if we believe that it is
      necessary to protect our rights or property.
    </li>
  </ul>
  <h2>How do we protect your personal information?</h2>
  <p>
    We take the security of your personal information very seriously. We use a
    variety of security measures to protect your personal information from
    unauthorized access, use, disclosure, alteration, or destruction, including:
  </p>
  <ul>
    <li>
      Physical security: We store your personal information in secure
      facilities.
    </li>
    <li>
      Technical security: We use encryption and other security measures to
      protect your personal information when it is transmitted over the
      internet.
    </li>
    <li>
      Access controls: We limit access to your personal information to
      authorized employees and contractors who need to access it in order to
      perform their job duties.
    </li>
  </ul>
  <h2>Your rights</h2>
  <p>
    You have the following rights with respect to your personal information:
  </p>
  <ul>
    <li>
      The right to access your personal information: You have the right to
      request a copy of your personal information that we hold.
    </li>
    <li>
      The right to correct your personal information: If your personal
      information is inaccurate or incomplete, you have the right to request
      that we correct it.
    </li>
    <li>
      The right to delete your personal information: You have the right to
      request that we delete your personal information.
    </li>
    <li>
      The right to object to the processing of your personal information: You
      have the right to object to the processing of your personal information
      for certain purposes, such as marketing.
    </li>
    <li>
      The right to withdraw your consent: If you have given us your consent to
      process your personal information, you have the right to withdraw your
      consent at any time.
    </li>
  </ul>
  <h2>How to contact us</h2>
  <p>
    If you have any questions about this Privacy Policy or about how we handle
    your personal information, please contact us at: privacy@billmyplate.com
  </p>

  <h2>Changes to this Privacy Policy:</h2>

  <p>
    We may update this Privacy Policy from time to time. We will notify you of
    any changes by posting the new Privacy Policy on our website. The new
    Privacy Policy will be effective immediately upon its posting on our
    website.
  </p>

  <p>Effective date: This Privacy Policy is effective as of [date].</p>
</div>
