<section class="contact">
  <h1 class="mat-h1">
    Have Bill My Plate in your gas station or drive through for FREE!
  </h1>
  <h4>Leave your contact and we will get in touch</h4>

  <div class="form">
    <mat-form-field [appearance]="'outline'">
      <mat-label>Email or phone number</mat-label>
      <input required matInput placeholder="813-888-8888" #contactInput />
      <mat-error>Please provide a valid email or phone number</mat-error>
    </mat-form-field>
    <div>
      <button mat-flat-button color="primary" (click)="sendContact(contactInput.value)">Submit</button>
    </div>
  </div>
</section>