import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideRouter } from '@angular/router';
import { HomeComponent } from './app/home/home.component';
import { TosComponent } from './app/tos/tos.component';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PrivacyComponent } from './app/privacy/privacy.component';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { provideToastr } from 'ngx-toastr';

bootstrapApplication(AppComponent, {
  providers: [
    provideAnimations(),
    provideEnvironmentNgxMask(),
    provideToastr(),
    provideRouter([
      { path: '', component: HomeComponent },
      { path: 'tos', component: TosComponent },
      { path: 'privacy', component: PrivacyComponent },
    ]),
  ],
});
